import { _createApp } from './base'
import { abtservice } from 'public/src/services/abt/index.js'

const homepageClientRender = async (context) => {
  try {
    // 埋点需要 实验提前请求
    const allChannelAbt = context.allChannelAbt || []
    const posKeys = allChannelAbt?.map?.(abtInfo => abtInfo.sceneStr)?.join?.() || ''
    if (posKeys) {
      // eslint-disable-next-line
      await abtservice.getUserAbtResult({ posKeys })
    }
  } catch (error) {
    console.log(error)
  }

  const { app } = _createApp(context)
  app.mount('.j-home-index')
}

homepageClientRender(window.gbRawData)
