<template>
  <AppCcc
    class="home-index__ccc-policybanner"
    :content="policyBannerData?.content"
    :context="context"
    :scene-data="policyBannerSceneData"
    :cate-links="policyBannerData?.cateLinks"
  />
  <AppCcc
    class="home-index__ccc-content"
    :content="content"
    :context="context"
    :scene-data="sceneData"
    :cate-links="cateLinks"
  />
  <!-- style gallery & love romwes -->
  <div
    class="home-index__ccc-content"
  >
    <ClientOnly>
      <StyleGallery v-if="showStyleGallery" />
    </ClientOnly>
    <!-- 可以SSR -->
    <LoveRomwe
      v-if="showLoveRomwes"
      :language="context.language"
      :public-cdn="context.PUBLIC_CDN"
      :css-right="context.GB_cssRight"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import Analysis from './analysis'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { prefetchResource } from 'public/src/services/prefetchResource'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'

export default {
  name: 'HomePage',
  components: {
    ClientOnly,
    StyleGallery: defineAsyncComponent(
      () => import(/* webpackChunkName: "style-gallery" */'public/src/pages/config_index/components_v2/StyleGallery.vue'),
    ),
    LoveRomwe: defineAsyncComponent(
      () => import(/* webpackChunkName: "love-romwe" */'public/src/pages/config_index/components_v2/LoveRomwe.vue'),
    ),
    AppCcc
  },
  props: {
    context: {
      type: Object,
      default: () => ({})
    }
  },
  provide() {
    const { homeProductJumpAbtData = {} } = this.context
    return {
      cutImg: this.cutImg,
      homeProductJump: homeProductJumpAbtData?.param?.HomeProductJump || '',
      metricsConfig: { // 监控配置
        reportMetrics: {
          cccClick: true,
          hideCompMonitor: true
        },
        pageName: 'page_home',
      }
    }
  },
  computed: {
    // 频道数据结果
    channelContent() {
      return this.context?.content || {}
    },
    policyBannerData() {
      return this.context?.policyBannerData
    },
    content() {
      return this.channelContent?.content || []
    },
    sceneData() {
      const { content = {}, channelInfo = {} } = this.context || {}
      return {
        pageType: content?.pageType || '',
        pageFrom: 'home',
        poskey: channelInfo?.abt?.sceneStr,
        sceneName: channelInfo?.channelName,
        tabName: channelInfo?.channelName,
      }
    },
    policyBannerSceneData() {
      const { pageType = '' } = this.policyBannerData || {}
      return {
        ...this.sceneData,
        pageType
      }
    },
    cateLinks() {
      return this.context?.content?.cateLinks
    },
    showStyleGallery () {
      return this.context?.showStyleGallery
    },
    showLoveRomwes () {
      return this.context?.showLoveRomwes
    },
    resourceSDK () {
      const { RESOURCE_SDK = {} } = this.context || {}
      return RESOURCE_SDK
    }
  },
  mounted() {
    this.analysisPv()
    this.prefetchResources()
    this.setMonitor()
  },
  methods: {
    // 业务埋点监控
    setMonitor () {
      metricPageSuccess({ 
        page: 'page_home',
        status: (this.context?.content?.content?.length) ? '1' : '0',
      })
    },
    analysisPv () {
      const { channelInfo, content } = this.context
      const activeCate = document.querySelector('.j-nav-first-cate.cate-active')

      // 赋值信息，同时会发pv
      Analysis.refreshAnalysisParams({
        params: {
          tab_id: channelInfo?.channelId,
          tab_title: channelInfo?.channelName,
          tab_hole: Number(activeCate?.getAttribute('data-index')) + 1,
          mod1_id: '', // self.content && self.content.template_id || ''
          ccc_abt_type: content?.abtBranch || '',
        }
      })

    },
    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.resourceSDK

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    prefetchResources() {
      // el 必须是渲染完的
      prefetchResource.listen({
        el: document.querySelector('.j-home-index'),
        prefetchList: [
          // 列表
          {
            chunkName: 'product_list_v2',
            relType: 'prefetch'
          },
          // 详情
          {
            chunkName: 'goods_detail_v3',
            relType: 'prefetch'
          },
          // 个人中心
          {
            chunkName: 'user_index',
            relType: 'prefetch'
          },
        ],
        prefetchCallback: ({ status, info }) => {
          console.log('prefetchCallback', status, info)
        },
        delay: 5000, // 默认两秒
      })
    },
  }
}
</script>

<style lang="less" scoped>
.home-index__ccc-content,
.home-index__ccc-policybanner {
  padding-left: 48px;
  padding-right: 48px;
}
</style>
